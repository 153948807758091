.instructions > .top-container {
  padding: 3rem 0 4rem;
  max-width: 40rem;

  & > .logo {
    margin-top: -2rem;
    margin-bottom: 4rem;
  }

  .text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    p {
      margin-bottom: 2rem;
    }
  }

  h2 {
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
    width: 100%;
    text-align: center;

    margin: 1rem 0;
    // color: var(--black);
  }

  strong {
    font-size: 18px;
    line-height: 20px;
    font-weight: 700;
    width: 100%;
    text-align: center;
    // color: var(--black);
    letter-spacing: 1px;

    margin: 1rem 0;
  }

  .buttons-container-custom button:first-of-type {
    margin-right: 1rem;
  }

  p {
    font-size: var(--small-m);
    line-height: var(--medium-s);
    font-weight: 400;
    text-align: center;
    // color: var(--black);

    width: 86%;
    max-width: 40rem;

    margin: 1rem 0;
  }
}
