.followup-user-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  padding: 1rem;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 0.5rem;
}
