.question {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  & > strong {
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;

    text-align: center;
    width: calc(90% - 2rem);

    margin-bottom: 2rem;
  }

  & > .points {
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    user-select: none;

    text-align: right;
    width: 100%;
    color: #00002760;

    margin-bottom: 0;
  }

  .buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    width: 100%;
    margin-bottom: 1rem;

    button {
      margin-right: 0;
      margin-left: 0;

      &.back {
        padding: 1rem;
        position: absolute;
        right: 0;
        top: 0;
      }

      svg {
        height: 18px;

        path {
          stroke: #fff;
          stroke-width: 64px;
        }
      }
    }
  }
}
