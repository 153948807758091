.scheduler-dp {
  user-select: none;

  padding-bottom: 3rem;

  & > .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    padding: 1rem 0 1rem;

    width: 90%;

    & > p,
    & > span {
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;

      margin: 4rem 0rem 0.5rem;
      width: 90%;
      text-align: center;
    }

    & > span {
      font-weight: 500;
      margin-bottom: 3rem;
    }

    @media screen and (max-width: 1110px) {
      .calendly-inline-widget {
        height: 540px !important;
      }

      width: 100%;
      padding: 0;
    }
  }
}
