.radio {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  width: 100%;

  padding: 2rem 1.5rem;

  border-radius: var(--border-radius);
  box-shadow: 0 0 12px 0 var(--shadow);

  & > p,
  & > strong {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;

    padding: 0 0.5rem;

    margin-bottom: 2rem;
    user-select: none;

    white-space: pre-line;
  }

  & > strong {
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;

    width: 100%;
    text-align: center;
  }

  label {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;

    width: 100%;
    min-height: 44px;

    padding: 0 0.5rem;
    margin: 0;

    border-radius: var(--border-radius);
    user-select: none;

    p {
      padding: 0.5rem 0;
      font-size: 14px;
      line-height: 16px;
      font-weight: 600;
    }

    input {
      display: none;
    }

    &::before {
      content: "";
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;

      width: 1.5rem;
      height: 1.5rem;
      margin-right: 1rem;

      background-color: var(--light-shadow);
      border-radius: 50%;

      transition-duration: var(--hover);
      transition-timing-function: ease-in-out;
      transition-property: background-color, box-shadow;
    }

    &.checked::before {
      transition: none;
      background-color: var(--green);
      box-shadow: 4px 4px 8px -4px var(--shadow);
    }

    input[type="text"] {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      border: none;
      border-bottom: 2px solid var(--light-shadow);
      width: 100%;

      margin-left: 0.75rem;
      padding: 0.25rem 0;
      margin-bottom: -2px;

      transform: scale(0.875);
      transform-origin: left center;
      margin-right: -14.28%;

      font-size: 16px;
      line-height: 16px;
      font-weight: 600;

      transition: var(--hover) ease-in-out border-bottom-color;
      outline: none;

      &:focus {
        transition: none;
        border-bottom-color: var(--green);
      }

      &::placeholder {
        font-size: 16px;
        line-height: 16px;
        font-weight: 600;
        color: var(--light-shadow);
        opacity: 1;
      }
    }
  }
}
