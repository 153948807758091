/* ELIMINAMOS LOS ESTILOS DE NAVEGADOR */

/* APP */

* {
     margin: 0;
}

body {
     margin: 0;
     font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
          "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
          "Helvetica Neue", sans-serif;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     height: 100vh;
}


code {
     font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
          monospace;
}

.app {
     display: flex;
}

/* SIDEBAR */

.sidebar {
     display: flex;
     flex-direction: column;
     flex: 0.25;
     height: 100vh;
     background-color: white;
     overflow: scroll;
     -ms-overflow-style: none;
     scrollbar-width: none;
     margin-top: 2px;
}

.sidebar::-webkit-scrollbar {
     display: none;
}

.sidebar__top {
     display: flex;
     justify-content: space-between;
     align-items: center;
     padding: 20px;
     background-color: white;
     color: white;
     border-bottom: 1px solid #0d2e68;
}

.sidetitle__top {
     color: #0d2e68;
}

.sidetitle__names {
     color: #0d2e68;
     border: #5df99c;
     border-radius: 5px 1px 1px 1px;
}

.sideicon__top {
     color: #E9A757;
     cursor: pointer;
}

.sidebar__channels {
     flex: 1;
}

.sidebar__addChannel:hover {
     color: white;
}

.sidebar__table {
     margin: 0 auto;
     justify-content: center;
}

.sidebar__channelsHeader {
     display: flex;
     justify-content: center;
     align-items: center;
     padding: 10px;
     background-color: white;
     color: gray;
}

.sidebar__header {
     display: flex;
     align-items: center;
}

.sidebar__profile {
     display: flex;
     justify-content: space-between;
     align-items: center;
     color: gray;
     background-color: #2f3135;
     padding: 10px;
     border-top: 1px solid gray;
}

.sidebar__profileInfo {
     flex: 1;
     padding: 10px;
}

.sidebar__profileInfo > h3 {
     color: white;
}

.sidebar__profileIcons > .MuiSvgIcon-root {
     padding: 10px;
}

/* CANALES DE SIDEBAR */

.sidebarChannel:hover > h4 {
     color: white;
     background-color: #40464b;
}

.sidebarChannel > h4 {
     display: flex;
     padding-left: 15px;
     align-items: center;
     background-color: #2f3135;
     color: gray;
     cursor: pointer;
}

.sidebarChannel__hash {
     font-size: 30px;
     padding: 8px;
}

/* CHAT */

.chat {
     display: flex;
     flex-direction: column;
     flex: 0.75;
     height: 100vh;
}

.chat__input {
     color: lightgray;
     display: flex;
     align-items: center;
     justify-content: space-between;
     padding: 15px;
     border-radius: 5px;
     margin: 20px;
     margin-bottom: 0;
     /* border-top: 1px solid gray; */
     background-color: #292929;
     height: 60px;
     border-radius: 10px;
}

#input-file {
     position: absolute;
     width: 0;
     height: 0;
     display: none;
}

.chat-input-container {
     width: 100%;
     display: flex;
     display: flex;
     justify-content: center;
     padding-bottom: 20px;
     z-index: 100;
}

.chat__input > form > input {
     padding: 15px;
     background: transparent;
     border: none;
     outline-width: 0;
     color: white;
     font-size: large;
     width: 100%;
}

.chat__input > form {
     flex: 1;
}

.chat__inputButton {
     display: none;
}

.chat__inputIcons > .MuiSvgIcon-root {
     padding: 2px;
}

.chat__inputIcons {
     cursor: pointer;
}

.chat__messages {
     flex: 1;
     overflow: scroll;
}

.chat__messages::-webkit-scrollbar {
     display: none;
}

.chat__messages {
     -ms-overflow-style: none;
     scrollbar-width: none;
}

/* MENSAJE */

.message {
     display: flex;
     align-items: center;
     padding: 20px;
     color: black;
     z-index: 1;
     /* justify-content: flex-end; */
}

.message.other {
     display: flex;
     align-items: center;
     padding: 20px;
     color: white;

     flex-flow: row-reverse wrap-reverse;
}

.message__info {
     background: #292929;
     color: white;
     width: 70%;
     min-width: 50px;
     padding: 10px;
     border-radius: 9px 9px 9px 9px;
     box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.07);
}

.a-right img {
     padding: 5px;
     width: 40px;
}

.message__other {
     /* margin-left: 20px; */
     background: #0053B3;
     width: 70%;
     min-width: 50px;
     padding: 10px;
     border-radius: 9px 9px 9px 9px;
     box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.07);
     animation: fadeInMessage 0.2s ease-in-out;
     z-index: 1;
     color: #fff;
}

.message.other svg, .message svg {
     width: 45px !important;
}

.message__timestamp {
     color: white;
     margin-left: 20px;
     font-size: x-small;
}

/* CHAT HEADER */
.chatHeader {
     display: flex;
     justify-content: space-between;
     align-items: center;
     color: gray;
     padding: 10px;
     z-index: 100;
}

.chatHiddenScroll{
     overflow-y: scroll; 
     scrollbar-width: none; 
     -ms-overflow-style: none;
}

.hiddenScrollBottom{
     overflow-x: scroll; 
     scrollbar-width: none; 
     -ms-overflow-style: none;
}

.chatHeader__hash {
     color: #0d2e68;
     font-size: 30px;
     padding: 10px;
}

.chatHeader__video {
     font-size: 16px;
     cursor: pointer;
     align-items: center;
     display: flex;
}

.chatHeader__left > h3 {
     display: flex;
     align-items: center;
     margin-left: 10px;
}

.chatHeader__right {
     display: flex;
     align-items: center;
     justify-content: space-between;
     margin-right: 10px;
}

.chatHeader__right > .MuiSvgIcon-root {
     padding: 5px;
     cursor: pointer;
     font-size: 10px;
     width: 5px !important;
}

.chatHeader__right > .MuiSvgIcon-root:hover {
     color: white;
}

.chatHeader__right > svg {
     width: 10px !important;
}

.chatHeader__search {
     display: flex;
     align-items: center;
     color: gray;
     background-color: #2f3135;
     border-radius: 3px;
     padding: 3px;
}

.chatHeader__search > input {
     background: transparent;
     outline-width: 0;
     color: white;
     border: none;
}

.courses-container {
     width: calc(100% - 60px);
}

.thicker-container {
     width: calc(100% - 302px);
}

.chat-box {
     animation: fadeInMessage 0.2s ease-in-out;
}

.bubble-container {
     position: fixed;
     width: 100px;
     height: 100px;
     bottom: 40px;
     right: 60px;
     z-index: 1000;
     cursor: pointer;
     transition: all 0.2s ease-in-out;
}

.bubble-container img {
     width: 100%;
}

.hide-bubble {
     transform: translate(0, -10px);
     opacity: 0;
     visibility: hidden;
}

.btn-modal-redirect {
     background: #2c96fb;
     color: #fff;
     width: 120px;
     border-radius: 30px;
     padding: 10px 0;
     font-weight: 800;
     text-transform: uppercase;
}

.not-focus {
     outline: none;
}

/* @keyframes identifier {
     
} */

@keyframes fadeInMessage {
     0% {
          opacity: 0.7;
          transform: translate(0, 5px);
     }
     100% {
          opacity: 1;
          transform: translate(0, 0);
     }
}