.auto-diagnosis > .top-container {
  justify-content: flex-start;

  padding-top: 4rem;

  max-width: 30rem;

  & > strong {
    font-size: var(--small-m);
    line-height: var(--medium-s);
    font-weight: 500;
    text-align: center;

    width: 86%;

    margin-bottom: 2.5rem;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 90%;

    background-color: var(--white);

    padding-bottom: 4rem;

    .profiles {
      display: grid;
      grid-template-columns: 1fr;
      gap: 2rem;

      width: 100%;

      padding-bottom: 2rem;
      margin-bottom: 2rem;

      border-bottom: 2px dashed var(--green);

      .card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        padding: 2rem;

        border-radius: var(--border-radius);
        box-shadow: 0 0 12px 0 var(--shadow);

        .text {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;

          h2 {
            font-size: 24px;
            line-height: 24px;
            font-weight: 700;

            padding-bottom: 0.5rem;
            border-bottom: 2px solid var(--green);

            margin-bottom: 2rem;
          }

          .description {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            p {
              font-size: 14px;
              line-height: 18px;
              font-weight: 400;
              margin-bottom: 0.25rem;

              display: flex;
              justify-content: flex-start;
              align-items: flex-start;

              &::before {
                content: "";
                display: flex;
                flex-shrink: 0;
                margin-top: calc(6px);
                margin-right: 0.5rem;

                width: 0.375rem;
                height: 0.375rem;
                background-color: var(--green);
              }
            }

            strong {
              font-size: 14px;
              line-height: 18px;
              font-weight: 600;

              margin-bottom: 1.25rem;

              &:last-of-type {
                margin-top: 1.25rem;
              }
            }
          }
        }

        span {
          font-size: 14px;
          line-height: 14px;
          font-weight: 600;

          margin-top: 1.5rem;
        }
      }
    }

    .question {
      max-width: 38rem;
    }
  }

  @media screen and (min-width: 640px) {
    max-width: 34rem;
  }
  @media screen and (min-width: 720px) {
    max-width: 38rem;
  }

  @media screen and (min-width: 800px) {
    max-width: 90%;

    & > .container > .profiles {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media screen and (min-width: 1280px) {
    & > .container {
      flex-direction: row-reverse;
      align-items: flex-start;
      justify-content: space-between;
      max-width: 1100px;

      & > .profiles {
        border-bottom: none;
        width: 66.6%;
      }

      & > .question {
        padding-right: 1rem;
        margin-right: 1rem;
        width: 33.3%;
      }
    }
  }
  @media screen and (min-width: 1400px) {
    & > .container {
      max-width: 1300px;
    }
  }
}
