.welcome > .top-container {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 90%;
    max-width: 40rem;
    padding: 2rem 2rem 3rem;

    background-color: var(--white);
    border-radius: var(--radius);
    box-shadow: 0 0 16px 0 var(--shadow);

    span {
      width: 100%;

      font-size: var(--small-s);
      line-height: var(--small-s);
      text-align: center;
      font-style: italic;

      margin-bottom: 1rem;
    }

    h1 {
      width: 100%;

      font-size: var(--medium-m);
      line-height: var(--medium-l);
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;

      margin-bottom: 2rem;
    }

    p {
      width: 100%;

      font-size: var(--small-s);
      line-height: var(--small-m);
      text-align: center;
    }

    button {
      svg {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-left: 0.5rem;

        width: 18px;
        height: 18px;

        animation: spin 1s linear infinite;

        path {
          stroke: var(--white);
        }

        @keyframes spin {
          0% {
            transform: rotateZ(0);
          }
          100% {
            transform: rotateZ(360deg);
          }
        }
      }
    }
  }
}
