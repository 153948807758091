.instructions > .top-container {
     & > .logo {
          margin-top: -2rem;
          margin-bottom: 4rem;
     }

     .text {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;

          p {
               margin-bottom: 2rem;
          }
     }

     p {
          font-size: var(--small-m);
          line-height: var(--medium-s);
          font-weight: 400;
          text-align: center;

          width: 86%;
          max-width: 40rem;

          margin-bottom: 4rem;

          strong {
               text-transform: uppercase;
               font-weight: 600;
          }
     }
}
