.video {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  & > strong {
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;

    text-align: center;
    width: calc(90% - 2rem);

    margin: 1rem 0 1.5rem;
  }

  .iframe {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: calc(153px + 1rem);

    background-color: var(--white);
    border-radius: var(--radius);
    box-shadow: 0 0 12px 0 var(--shadow);

    margin-bottom: 1rem;
    padding: 0.5rem;

    position: relative;

    iframe {
      position: relative;
      z-index: 10;

      width: 100%;
      min-height: 144px;
      height: 100%;
      background-color: var(--green);

      border-radius: var(--border-radius);
    }

    @media screen and (min-width: 340px) {
      height: calc(163px + 1rem);
    }
    @media screen and (min-width: 360px) {
      height: calc(173px + 1rem);
    }
    @media screen and (min-width: 375px) {
      height: calc(181px + 1rem);
    }
    @media screen and (min-width: 390px) {
      height: calc(188px + 1rem);
    }
    @media screen and (min-width: 412px) {
      height: calc(200px + 1rem);
    }
    @media screen and (min-width: 414px) {
      height: calc(201px + 1rem);
    }
    @media screen and (min-width: 460px) {
      height: calc(224px + 1rem);
    }
    @media screen and (min-width: 520px) {
      height: calc(254px + 1rem);
    }
    @media screen and (min-width: 560px) {
      height: calc(275px + 1rem);
    }
    @media screen and (min-width: 600px) {
      height: calc(315px + 1rem);
    }
  }
}
