.form > .top-container {
  strong {
    font-size: var(--small-m);
    line-height: var(--small-m);
    font-weight: 500;

    margin-bottom: 2.5rem;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 90%;
    max-width: 40rem;
    padding: 2rem;

    border-radius: var(--radius);

    background-color: var(--white);
    box-shadow: 0 0 16px 0 var(--shadow);

    label {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      width: 100%;

      margin-bottom: 1rem;

      p {
        font-size: var(--small-s);
        line-height: var(--small-s);

        margin-bottom: 0.5rem;

        span {
          color: var(--red);
        }

        .asterisk {
          margin: 0 0.25rem;
        }
      }

      input {
        width: 100%;
        padding: 6px 1rem;

        font-size: var(--small-s);
        line-height: var(--small-s);

        border: 1px solid var(--purple);
        border-radius: var(--border-radius);

        &::placeholder {
          color: var(--black);
          opacity: 0.3;

          font-size: var(--small-s);
          line-height: var(--small-s);
        }
      }
    }

    button {
      margin-top: 0.5rem;

      
    }
  }
}
