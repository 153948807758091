body {
  --tw-bg-opacity: 1;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 100;
  }
}

.main-logo {
  width: 210px;
}

.no-m svg {
  margin-right: 0 !important;
}

.icon-container ion-icon {
  color: #7DB8F2;
  margin: 0;
  font-size: 24px;
  margin-bottom: 15px;
  cursor: pointer;
}

.icon-box-container ion-icon {
  color: #1e3a8a;
  position: relative;
  right: 2px;
  font-size: 12px;
  transform: rotate(180deg);
  transition: transform 0.2s ease-in-out;

  height: 1rem;
  width: 1rem;
}

.rotate-arrow ion-icon {
  transform: rotate(0deg);
  color: #1e3a8a;
}

.icon-box-container {
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  width: 1.5rem;
  height: 3rem;
  border-top-right-radius: 9999999999px;
  border-bottom-right-radius: 9999999999px;

  top: calc(50% - 1.5rem);
  right: 0;
  transform: translate(98%, -50%);
  background: #fff;
  /* transition: opacity 0.2s ease-in-out; */
}

/* .icon-box-container:hover {
  transform: translate(98%, -50%);
  opacity: 1;
} */

@media screen and (max-width: 1023px) {
  .course-nav-id {
    width: 100%;
  }
}

.relative:hover .icon-box-container {
  transform: translate(98%, -50%);
  opacity: 1;
}

.op-scroll::-webkit-scrollbar-track {
  background: #1e3b8a31 !important;
}

.op-scroll::-webkit-scrollbar-thumb {
  background: #d1d5db31 !important;
}

.op-scroll:hover::-webkit-scrollbar-track {
  background: #1e3a8a !important;
}

.op-scroll:hover::-webkit-scrollbar-thumb {
  background: #d1d5db !important;
}

.a-icon svg path {
  fill: #7DB8F2;
}

.input-pre {
  width: 100%;
  font-family: "Poppins";
  height: 33px;
  border-radius: 30px;
  border: 1px solid #a7a7a7;
  padding-left: 15px;
  transition: all 0.2s ease-in-out;
  outline: 1px solid #fff;
}

.input-pre:focus {
  outline: 1px solid #2c96fb;
  border: 1px solid #2c96fb;
  box-shadow: 0 0 7px #8dc8ff;
}

.error-input-pre,
.error-input-pre:focus {
  outline: 1px solid #fd2c1d;
  border: 1px solid #fd2c1d;
  box-shadow: 0 0 7px #fd2c1d;
}

.pre-label {
  margin-left: 5px;
}

.btn-pre-form {
  color: #fff;
  background: #2c96fb;
  width: 180px;
  font-family: inherit;
  text-transform: uppercase;
  height: 45px;
  font-size: 24px;
  border-radius: 33px;
  font-weight: 700;
  margin-top: 50px;
  transition: all 0.2s ease-in-out;
}

.btn-pre-form:hover {
  box-shadow: 0 0 15px #8dc8ff;
}

.desktop-form {
  display: none;
}

@media screen and (min-width: 900px) {
  .desktop-form {
    display: block;
  }
  .mobile-form {
    display: none;
  }
}

.show-card-fadeIn {
  animation: fadeInCard 0.2s ease-in-out;
}

@keyframes fadeInCard {
  0% {
    opacity: 0;
    transform: translate(0, -10px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.to-green:hover > .MuiSvgIcon-root path {
  fill: #5df99c !important;
}

.bubble-img {
  width: 100%;
}

.bubble-text {
  top: 30%;
  line-height: 20px;
}

.show-peace-page {
  animation: fadeInCard 0.2s ease-in-out;
}

.professors-buttons {
  font-family: inherit;
  font-weight: 600;
  border: 1px solid rgb(55 65 81);
  border-radius: 8px;
  padding: 4px 10px;
  color: rgb(55 65 81);
  transition: all 0.2s ease-in-out;
}

.professors-buttons:hover {
  background-color: #4bd88b;
  border-color: #4bd88b;
  color: #fff;
}

.mini-bubble-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.circle-title {
  font-size: 29.33px;
  color: #fff;
  font-weight: 700;
  font-family: "Poppins";
}

.inner-bubble-circle {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-width: 70px;
}

.inner-bubble-circle::after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.mini-bubble-icon {
  position: absolute;
  top: -7px;
  right: -7px;
  background: #fff;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.267);
}

.mini-bubble-title {
  font-size: 12px;
  font-weight: 700;
  margin-top: -7px;
}

.user-card-header {
  display: flex;
  gap: 28px;
}

.bubble-img-card {
  width: 120px;
}

.user-name-h3 {
  font-size: 26px;
  color: #fff;
  font-weight: 400;
  text-transform: capitalize;
}

.occupation-user {
  color: #5df99c;
  font-size: 22px;
  text-transform: capitalize;
}

.location-user {
  color: #fff;
  margin-top: 10px;
  text-transform: capitalize;
  display: flex;
  gap: 7px;
}

.user-card-form {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding-top: 40px;
}

.label-card {
  display: flex;
  flex-direction: column;
  color: #7DB8F2;
  gap: 8px;
  font-weight: 400;
  font-size: 15px;
}

.input-card {
  background: #333333;
  border: none;
  outline: unset;
  border-radius: 5px;
}

.label-user-input {
  color: #5df99c;
}

.input-card:hover {
  background: #292929;
}

.target-icon {
  width: 15px;
  cursor: pointer;
}

.edit-photo-container {
  background: #5df99c;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.edit-photo-container:hover {
  box-shadow: 0 0 5px #5df99c;
}

.edit-photo-container img {
  width: 16px;
}

.user-img-container {
  overflow: hidden;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #fff;
  transition: all 0.2s ease-in-out;
}

.user-img-container:hover {
  box-shadow: 0 0 5px #fff;
}

.user-img-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blue-square {
  animation: fadeIn 0.4s cubic-bezier(0, 1.03, 0.55, 1.06);
}

.blue-square-d {
  opacity: 0;
  animation: fadeIn 0.4s cubic-bezier(0, 1.03, 0.55, 1.06) forwards;
  animation-delay: 0.1s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate(0, -20px);
  }
  100% {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.message-profile {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  margin-left: 8px;
}

.message-profile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.bg-hl {  
  animation: fadeBackToDefault 7s forwards; /* Animation lasts for 3 seconds */
}

@keyframes fadeBackToDefault {
  from {
    background-color: #6AB0DD; /* Initial color */
  }
  to {
    background-color: inherit; /* Back to default color */
  }
}