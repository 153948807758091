@import url("https://fonts.googleapis.com/css2?family=Barlow:wght@500;600;700;800&display=swap");

.calendar-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  min-height: 100vh;
  gap: 30px;
}

.react-calendar {
  border: none;
  border-radius: 14px;
}

.main-title {
  width: 100%;
  font-size: 26px;
  line-height: 28px;
  font-weight: 700;
}

.blue-box {
  width: 100%;
  background: transparent;
  color: #fff;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border-radius: 8px;
}

.calendar-inner-container {
  display: flex;
  gap: 12px;
  width: 80%;
  justify-content: center;
  position: relative;
  z-index: 1;
}

.hours-container {
  background: #fff;
  width: 100%;
  max-width: 210px;
  border-radius: 14px;
  padding: 18px;
  display: flex;
  flex-direction: column;
  gap: 7px;
}

.blue-border {
  border: 1px solid #a7caf0;
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  cursor: pointer;
  color: #2596be;
  font-weight: 700;
  font-size: 13.7px;
  transition: all 0.1s ease-in-out;
}

.active-border {
  background: #0d2e68;
  color: #fff;
}

.blue-border:hover {
  background: #0d2e68;
  color: #fff;
}

.btn-blue {
  background: #0d2e68;
  color: #fff;
  text-transform: uppercase;
  font-weight: 100;
  width: 210px;
  border: none;
  border-radius: 30px;
  padding: 10px 0;
  transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
}
.iframe-calendar-speaking {
  width: 100%;
}

.confirmation-modal-speaking {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .confirmation-modal-speaking {
    width: calc(100% - 48px);
  }
  .iframe-calendar-speaking {
    width: 100%;
    height: auto;
  }
}
@media screen and (min-width: 1050px) {
  .iframe-calendar-speaking {
    width: 800px;
    height: 1030px;
  }
}
@media screen and (min-width: 1150px) {
  .iframe-calendar-speaking {
    width: 900px;
    height: 1020px;
  }
}
@media screen and (min-width: 1340px) {
  .iframe-calendar-speaking {
    width: 1096px;
    height: 890px;
  }
}

.br-calendar {
  display: none;
}

.btn-blue:hover {
  background: #000027;
  color: #5df99c;
}

@media screen and (max-width: 960px) {
  .main-title {
    font-size: 24px;
  }
}

@media screen and (max-width: 560px) {
  .main-title {
    font-size: 20px;
  }

  .blue-box {
    min-height: 150px;
  }
  .calendar-inner-container {
    flex-direction: column;
    align-items: center;
  }
  .br-calendar {
    display: block;
  }
}

.animate-up {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  0% {
    transform: translate(-50%, 200%);
    opacity: 0;
  }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}