.image-type {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;

  & > strong {
    font-size: 16px;
    line-height: 18px;
    font-weight: 500;

    text-align: center;
    width: calc(90% - 2rem);

    margin-bottom: 2rem;
  }

  & > .image-container {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    margin-bottom: 2rem;

    border-radius: var(--border-radius);
    box-shadow: 0 0 12px 0 var(--shadow);
    overflow: hidden;

    padding: 0.5rem;

    & > img {
      width: 100%;
      border-radius: calc(var(--border-radius) / 2);
    }
  }
}
