.top-component {
  display: flex;
  justify-content: center;
  align-items: flex-start;

  height: calc(var(--unit) * 100);
  width: 100%;

  overflow: hidden;

  opacity: 0;
  animation: appear var(--animation) ease-in-out forwards;

  color: var(--black);

  @keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  & > .top-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    height: calc(var(--unit) * 100);
    overflow: hidden;

    position: relative;
  }
}
