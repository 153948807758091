:root {
  --green: #2de678;
  --blue: #2c96fb;
  --black: #000027;
  --purple: #2f32de40;
  --red: #ec5151;
  --white: #fff;

  --border-radius: 0.5rem;
  --radius: 1rem;
  --shadow: #2f32de30;
  --light-shadow: #2f32de20;
  --black-shadow: #00002740;
  --blue-shadow: #2c96fb50;
  --green-shadow: #2de67850;

  --small-s: 14px;
  --small-m: 16px;
  --small-l: 18px;
  --medium-s: 20px;
  --medium-m: 22px;
  --medium-l: 24px;

  --hover: 0.3s;
  --animation: 0.4s;

  $transition: "0.2s ease-in-out color";
}
